import React from 'react';
import 'whatwg-fetch';
import { v4 as uuidv4 } from 'uuid';

import { I18nextProvider } from 'react-i18next';

import { ApolloProvider } from '@apollo/client';
import { client } from './src/apollo-client';

import i18n from './src/i18next';
import { GlobalStyles } from './src/ui/styles';
import './src/styles/global.css';

function albacrossReveal() {
  const locale = process.env.GATSBY_LOCALE;

  let timeout = 0;
  function poll() {
    if (!(timeout > 60000)) {
      if (!window.AlbacrossReveal || !window.Cookiebot?.consented) {
        timeout += 50;
        return setTimeout(poll, 50);
      }
      if (!window.AlbacrossReveal.company) {
        timeout += 50;
        return setTimeout(poll, 50);
      }
    }

    try {
      if (window.AlbacrossReveal.company) {
        const companyName = window.AlbacrossReveal.company.name;
        const companyCountry = window.AlbacrossReveal.company.country;
        const companyFounded = window.AlbacrossReveal.company.founded_year;
        const companyAlexaRank = window.AlbacrossReveal.company.alexa_rank;
        const companyEmployeeMin = window.AlbacrossReveal.company.employees
          ? window.AlbacrossReveal.company.employees.from
          : '';
        const companyEmployeeMax = window.AlbacrossReveal.company.employees
          ? window.AlbacrossReveal.company.employees.to
          : '';
        const companyLinkedInCategory = window.AlbacrossReveal.company
          .linkedin_industry_code
          ? window.AlbacrossReveal.company.linkedin_industry_code.category
          : '';
        const companyFinancialsMin = window.AlbacrossReveal.company
          .financial_report
          ? window.AlbacrossReveal.company.financial_report.from
          : '';
        const companyFinancialsMax = window.AlbacrossReveal.company
          .financial_report
          ? window.AlbacrossReveal.company.financial_report.to
          : '';
        const companNACECategory = window.AlbacrossReveal.company.nace_code
          ? window.AlbacrossReveal.company.nace_code.category
          : '';
        const companyCity = window.AlbacrossReveal.company.address.city;
        const companyState = window.AlbacrossReveal.company.address.state;
        const companyOrgID = window.AlbacrossReveal.company.number;
        const companyZip = window.AlbacrossReveal.company.address.zip_code
          ? window.AlbacrossReveal.company.address.zip_code
          : '';
        const companyWebsite = window.AlbacrossReveal.company.url
          ? window.AlbacrossReveal.company.url
          : '';

        const query = JSON.stringify({
          query: `mutation {
            createLeadsAlbacrossReveal(
              name: "${companyName}"
              founded: "${companyFounded || ''}"
              country: "${companyCountry}"
              alexaRank: "${companyAlexaRank || ''}"
              employeeMin: "${companyEmployeeMin}"
              employeeMax: "${companyEmployeeMax}"
              linkedInCategory: "${companyLinkedInCategory}"
              financialsMin: "${companyFinancialsMin}"
              financialsMax: "${companyFinancialsMax}"
              naceCategory: "${companNACECategory}"
              city: "${companyCity || ''}"
              state: "${companyState || ''}"
              orgId: "${companyOrgID || ''}"
              zip: "${companyZip || ''}"
              website: "${companyWebsite}"
              locale: "${locale}"
              url: "${window.location.href}"
							sessionId: "${sessionStorage.getItem('sessionId')}"
            ) { result }
          }
          `,
        });

        fetch(process.env.GATSBY_BE_URL_DEV, {
          headers: { 'content-type': 'application/json' },
          method: 'POST',
          body: query,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((response) => {
            const id = response?.data?.createLeadsAlbacrossReveal?.result;
            sessionStorage.setItem('reveal', id);
          });
      }
    } catch (error) {
      console.log('Error with A');
    }

    return true;
  }
  poll();
}

export const onClientEntry = () => {
  if (!sessionStorage.getItem('sessionId')) {
    sessionStorage.setItem('sessionId', uuidv4());
  }
  if (!sessionStorage.getItem('landingPage')) {
    sessionStorage.setItem('landingPage', window.location.href);
  }
};

export const onInitialClientRender = () => {
  if (process.env.GATSBY_BW_ENV === 'prod') {
    albacrossReveal();
  }

  // To remove the ugly focus when using mouse
  document.body.addEventListener('mousedown', () => {
    document.body.classList.add('using-mouse');
  });
  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('using-mouse');
  });
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const isProd = process.env.GATSBY_BW_ENV === 'prod';

  if (isProd && prevLocation) {
    setTimeout(() => {
      const isRevealed = sessionStorage.getItem('reveal') || false;
      if (isRevealed) {
        const query = JSON.stringify({
          query: `mutation {
            updateLeadsAlbacrossReveal(
              id: "${isRevealed}"
              url: "${window.location.href}"
            ) { result }
          }
          `,
        });

        fetch(process.env.GATSBY_BE_URL_DEV, {
          headers: { 'content-type': 'application/json' },
          method: 'POST',
          body: query,
        });
      }
    }, 100);
  }

  let referrer = '';
  if (document.referrer && !prevLocation) {
    referrer = document.referrer;
  } else {
    referrer = prevLocation ? prevLocation.href : '';
  }

  const urlParams = new URLSearchParams(window.location.search);
  const originalReferrer = sessionStorage.getItem('adOriginalReferrer');

  if (!originalReferrer && document.referrer) {
    sessionStorage.setItem('adOriginalReferrer', referrer);
  }

  const adGclid = urlParams.get('gclid');
  if (adGclid) {
    sessionStorage.setItem('adGclid', adGclid);
  }

  const digParam = urlParams.get('dig');
  if (digParam) {
    sessionStorage.setItem('adCode', digParam);
  }
  if (document.body.classList.contains('overflow-hidden')) {
    document.body.classList.remove('overflow-hidden');
  }
};

export const wrapRootElement = ({ element }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <I18nextProvider i18n={i18n}>
    <GlobalStyles />
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </I18nextProvider>
);
